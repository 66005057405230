import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import styled from 'styled-components'

const Section = styled.div`
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 5;
  background-color: #1d1d1d;
`

const IndexPage = () => (
  <Layout headerTitle="Test">
    <SEO title="Home" keywords={[`Teamgeek`]} />
    <div>
      <Section />
    </div>
  </Layout>
)

export default IndexPage
